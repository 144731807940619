    /*
    Flaticon icon font: Flaticon
    Creation date: 24/10/2019 07:51
    */

    @font-face {
  font-family: "Flaticon";
  src: url("./fonts/Flaticon.eot");
  src: url("./fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./fonts/Flaticon.woff2") format("woff2"),
       url("./fonts/Flaticon.woff") format("woff"),
       url("./fonts/Flaticon.ttf") format("truetype"),
       url("./fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

    .fi:before{
        display: inline-block;
  font-family: "Flaticon";
  font-style: normal; 
  /*font-weight: normal;*/
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
    }
 
    .ce-export:before { content: "\f100"; }
.ce-share:before { content: "\f101"; }
.ce-walkthrough:before { content: "\f102"; }
.ce-idea:before { content: "\f103"; }
.ce-back:before { content: "\f104"; }
.ce-front:before { content: "\f105"; }
.ce-paste:before { content: "\f106"; }
.ce-copy:before { content: "\f107"; }
.ce-group:before { content: "\f108"; }
.ce-mesh:before { content: "\f109"; }
.ce-spiral:before { content: "\f10a"; }
.ce-shape:before { content: "\f10b"; }
.ce-sticker:before { content: "\f10c"; }
.ce-picture:before { content: "\f10d"; }
.ce-ellipse:before { content: "\f10e"; }
.ce-polygon:before { content: "\f10f"; }
.ce-textbox:before { content: "\f110"; }
.ce-text:before { content: "\f111"; }
.ce-circle:before { content: "\f112"; }
.ce-box:before { content: "\f113"; }
.ce-brush:before { content: "\f114"; }
    
    $font-Flaticon-export: "\f100";
    $font-Flaticon-share: "\f101";
    $font-Flaticon-walkthrough: "\f102";
    $font-Flaticon-idea: "\f103";
    $font-Flaticon-back: "\f104";
    $font-Flaticon-front: "\f105";
    $font-Flaticon-paste: "\f106";
    $font-Flaticon-copy: "\f107";
    $font-Flaticon-group: "\f108";
    $font-Flaticon-mesh: "\f109";
    $font-Flaticon-spiral: "\f10a";
    $font-Flaticon-shape: "\f10b";
    $font-Flaticon-sticker: "\f10c";
    $font-Flaticon-picture: "\f10d";
    $font-Flaticon-ellipse: "\f10e";
    $font-Flaticon-polygon: "\f10f";
    $font-Flaticon-textbox: "\f110";
    $font-Flaticon-text: "\f111";
    $font-Flaticon-circle: "\f112";
    $font-Flaticon-box: "\f113";
	$font-Flaticon-brush: "\f114";