.svg-gallery-modal{
	.results{
		max-height: 400px;
		overflow: auto;
		.gal-item{
			border: 4px solid transparent;
			width: 100px;
			height: 100px;
			margin: 0 auto;
			display: flex;
			align-items: center;
			overflow: hidden;
		}
		.selected .gal-item{ border-color: green; }
		
		.loading-pane{
			display: flex;
			flex-direction: column;
			min-height: 150px;
			padding: 15px;
			justify-content: center;
			align-items: center;
			
			.loading-pane-content{
				display: none;
				text-align: center;
				font-size: 24px;
				color: #aaa;
			}
		}
	}
	&.loading{
		.results .loading-pane .loading-pane-content{ display: block; }
	}
}